import React, { useState, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { FaUsers } from 'react-icons/fa';
import { RiCoinFill } from 'react-icons/ri';
import { BsFillInboxFill } from 'react-icons/bs';
import { FaInfoCircle, FaCheckCircle, FaTicketAlt } from "react-icons/fa";
import './App.css';
import { IoMdRemove } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";



const Bet = () => {
  const { user, updateUser } = useContext(UserContext);
  const [currentXValue, setCurrentXValue] = useState(0.00);
  const [betAmount, setBetAmount] = useState(1000);
  const [isGameActive, setIsGameActive] = useState(false);
  const [profit, setProfit] = useState(0);
  const [fixedProfit, setFixedProfit] = useState(0); // Suma de profit fixată la retragere
  const [fixedXValue, setFixedXValue] = useState(0); // Suma de X fixată la retragere
  const [isWithdrawn, setIsWithdrawn] = useState(false); // Stare pentru retragere
  const [gameInterval, setGameInterval] = useState(null);
  const [isRocketShaking, setIsRocketShaking] = useState(false);
  const [isRocketIgnited, setIsRocketIgnited] = useState(false);
  const [backgroundPositionY, setBackgroundPositionY] = useState('100%');
  const [countdown, setCountdown] = useState(3);
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [hasLaunched, setHasLaunched] = useState(false);
  const [isRocketFalling, setIsRocketFalling] = useState(false);
  const [showEndGameModal, setShowEndGameModal] = useState(false);
  const [showWithdrawMessage, setShowWithdrawMessage] = useState(false); // Mesaj retragere
  const [showNoTicketsMessage, setShowNoTicketsMessage] = useState(false); // Mesaj pentru lipsa biletelor
  const [showNoBalanceMessage, setShowNoBalanceMessage] = useState(false); // Mesaj pentru balanță insuficientă

  const [isGameFinished, setIsGameFinished] = useState(false); // Stare pentru a verifica dacă jocul s-a terminat


  const backgroundSpeedFactor = 1.0;

  // Funcție pentru generarea unui x random pentru explozie
  const generateExplosionValue = () => {
    const maxExplosion = 10;
    const minExplosion = 1.5;
    
    const randomFactor = Math.pow(Math.random(), 2); // Valorile mari sunt mai probabile
    return minExplosion + randomFactor * (maxExplosion - minExplosion);
  };

  const startGame = () => {
    if (user.rocketTicket < 1) {
      showTemporaryMessage(setShowNoTicketsMessage); // Mesaj pentru lipsa biletelor
      return;
    }

    if (user.starBalance < betAmount) {
      showTemporaryMessage(setShowNoBalanceMessage); // Mesaj pentru balanță insuficientă
      return;
    }

    updateUser({
      rocketTicket: user.rocketTicket - 1,
      starBalance: user.starBalance - betAmount,
    });

    setShowCountdownModal(true);

    let countdownValue = 3;
    setCountdown(countdownValue);

    const countdownInterval = setInterval(() => {
      countdownValue -= 1;
      setCountdown(countdownValue);

      if (countdownValue <= 0) {
        clearInterval(countdownInterval);
        setShowCountdownModal(false);
        startRocketAnimation();
      }
    }, 1000);
  };

  const startRocketAnimation = () => {
    setIsGameActive(true);
    setIsWithdrawn(false); // Resetăm retragerea
    setProfit(0);
    setFixedProfit(0); // Resetăm profitul fix
    setFixedXValue(0); // Resetăm X-ul fix
    setCurrentXValue(0);
    setBackgroundPositionY('100%');
    setHasLaunched(false);
    setIsRocketFalling(false); 
  
    const explosionValue = generateExplosionValue(); // Generăm valoarea de explozie
    let xValue = 0.00;
    let increment = 0.003; 
  
    setIsRocketShaking(true);
    setIsRocketIgnited(true);
  
    setTimeout(() => {
      setIsRocketShaking(false);
      setHasLaunched(true); 
  
      const interval = setInterval(() => {
        xValue += increment;

        if (xValue >= explosionValue) {
          clearInterval(interval);
          initiateRocketFall(); // Inițiem prăbușirea rachetei
        } else {
          setCurrentXValue(parseFloat(xValue.toFixed(2)));
          const adjustedBackgroundPosition = `${100 - (xValue / 10) * 100}%`;
          setBackgroundPositionY(adjustedBackgroundPosition);

          if (!isWithdrawn) { // Profitul se oprește doar dacă nu s-a retras deja
            setProfit(betAmount * (xValue + 1));
          }

          increment += xValue / 5000;
        }
      }, 100);
  
      setGameInterval(interval);
    }, 1000);
  };

  const initiateRocketFall = () => {
    setIsRocketFalling(true); 
    setIsGameActive(false);   
    setIsGameFinished(true); // Setăm starea de joc terminat

    setTimeout(() => {
      setIsRocketFalling(false);
      setShowEndGameModal(true); 
    }, 2000); 
};

  
const resetGame = () => {
  setCurrentXValue(0.00);
  setBetAmount(1000);
  setIsGameActive(false);
  setProfit(0);
  setFixedProfit(0); // Resetăm profitul fix
  setFixedXValue(0); // Resetăm X-ul fix
  setIsWithdrawn(false); // Resetăm starea de retragere
  setIsRocketShaking(false);
  setIsRocketIgnited(false);
  setBackgroundPositionY('100%');
  setCountdown(3);
  setShowCountdownModal(false);
  setHasLaunched(false);
  setIsRocketFalling(false);
  setShowEndGameModal(false);
  setShowWithdrawMessage(false); // Resetăm mesajul de retragere
  clearInterval(gameInterval); 
  setIsGameFinished(false); // Resetează starea jocului terminat
};

const showTemporaryMessage = (setMessageFunction) => {
  setMessageFunction(true);
  setTimeout(() => {
    setMessageFunction(false); // Mesajul dispare după 2 secunde
  }, 10000);
};
  // Afișăm mesajul de retragere
  const showTemporaryWithdrawMessage = () => {
    setShowWithdrawMessage(true);
    setTimeout(() => {
      setShowWithdrawMessage(false); // Mesajul dispare după 2 secunde
    }, 10000);
  };

  const withdrawProfit = () => {
    if (!isGameActive || isWithdrawn) return;

    const currentProfit = profit; // Fixăm profitul curent
    setFixedProfit(currentProfit); // Setăm profitul fix în momentul retragerii
    setFixedXValue(currentXValue); // Setăm x-ul fix în momentul retragerii
    setIsWithdrawn(true); // Setăm retragerea profitului
    showTemporaryWithdrawMessage(); // Afișăm mesajul de retragere

    updateUser({
      starBalance: user.starBalance + currentProfit, // Adăugăm profitul fix în cont
    });

    // Transformăm butonul în "WAITING" și îl dezactivăm până la sfârșitul rundei
    document.getElementById('betButton').classList.add('waiting-button');
  };

  const decreaseBetAmount = () => {
    setBetAmount((prevAmount) => Math.max(prevAmount - 1000, 1000));
  };

  const increaseBetAmount = () => {
    setBetAmount((prevAmount) => prevAmount + 1000);
  };

  const handleBetAmountChange = (event) => {
    let value = event.target.value;

    // Permitem ștergerea completă
    if (value === '') {
      setBetAmount(''); // Setăm inputul la gol
      return;
    }

    // Verificăm dacă valoarea introdusă este validă
    let parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 0) { // Permitem valori mai mari sau egale cu 0
      setBetAmount(parsedValue);
    }
  };

  const handleButtonClick = () => {
    if (isGameActive && !isWithdrawn) {
      withdrawProfit(); // Retragem profitul și continuăm jocul
    } else if (!isGameActive) {
      startGame(); // Începem o nouă rundă
    }
  };

  // Dacă utilizatorul părăsește inputul și câmpul e gol, setăm minimul 1000
  const handleBetAmountBlur = () => {
    if (betAmount === '' || betAmount < 1000) {
      setBetAmount(1000);
    }
  };

  return (
    <div
      className={`rocket-display ${isGameActive ? 'rocket-background-scroll' : ''} ${!isGameActive && isRocketIgnited ? 'rocket-background-fall' : ''}`}
      style={{ backgroundPositionY }}
    >
      {showCountdownModal && (
        <div className="game-countdown-modal">
          <h2>The round starts in:</h2>
          <div className="countdown-number">{countdown}</div>
        </div>
      )}

{showNoTicketsMessage && (
        <div className={`withdraw-message ${showNoTicketsMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'linear-gradient(112.6deg, rgb(56, 24, 40) -10.7%, rgb(23, 148, 185) 100.2%)' }}>
          <FaInfoCircle style={{ verticalAlign: 'middle' }}/>
          Not enough rocket tickets!
        </div>
      )}

      {showNoBalanceMessage && (
        <div className={`withdraw-message ${showNoBalanceMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'linear-gradient(112.6deg, rgb(56, 24, 40) -10.7%, rgb(23, 148, 185) 100.2%)' }}>
          <FaInfoCircle style={{ verticalAlign: 'middle' }}/>
          Not enough balance to place bet!
        </div>
      )}

{showWithdrawMessage && (
        <div className={`withdraw-message ${showWithdrawMessage ? 'show' : 'hide'}`} style={{ color: 'white', fontWeight: '500', background: 'radial-gradient(862px at 6% 18%, rgb(21, 219, 149) 9.4%, rgb(26, 35, 160) 83.6%)' }}>
          <FaCheckCircle style={{ verticalAlign: 'middle' }}/>
          Profit Withdrawn: {fixedProfit.toFixed(2)} $STAR at x{(fixedXValue + 1).toFixed(2)} {/* Afisăm și x-ul fixat */}
        </div>
      )}
     
      {showEndGameModal && (
        <div className="rankup-overlay">
        <div className="game-end-modal">
            <h2>The rocket has crashed!</h2>
            <p>Do you have more tickets? Let's play another round!</p>
            <button onClick={resetGame}>Back to game</button>
        </div>
        </div>
      )}

      <div className="rocket-game-stats">
        <div className="stat-box" style={{ justifyContent: 'end' }}>
          <p className="stat-label"><RiCoinFill />Balance:</p>
          <p className="stat-value">{user.starBalance.toLocaleString()} $STAR</p>
        </div>
        <div className="stat-box">
          <p className="stat-label"><FaTicketAlt />Tickets:</p>
          <p className="stat-value">{user.rocketTicket}</p>
        </div>
      </div>

      <div className='game-objects'>
        <div className="rocket-container">
          <div className="progress-bar-container">
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ height: `${(currentXValue / 10) * 100}%` }}
              />
              <img
                src="rocket.png"
                alt="Mini Rocket"
                className="mini-rocket"
                style={{ bottom: `${(currentXValue / 10) * 100}%` }}
              />
            </div>
          </div>

          <img
            src="/rocket.png"
            alt="Rocket"
            className={`rocket ${isRocketShaking ? 'rocket-shake' : hasLaunched ? 'rocket-float' : ''} ${isRocketFalling ? 'rocket-fall' : ''}`}
          />

          {isRocketIgnited && (
            <>
              <img
                src="propulsion.gif"
                alt="Rocket Propulsion"
                className={`rocket-thrust ${isRocketFalling ? 'hidden' : ''} ${isRocketShaking ? 'rocket-shake' : ''} ${hasLaunched ? 'rocket-float' : ''}`}
              />
              <div className="rocket-smoke"></div>
            </>
          )}

          <div className="current-x-value">x{(currentXValue + 1).toFixed(2)}</div>
        </div>
      </div>

      <div className="game-controls">
        <div className="bet-amount">
          
          <input 
            type="number"
            value={betAmount}
            onChange={handleBetAmountChange}
            onBlur={handleBetAmountBlur} // Setăm minimul de 1000 dacă e gol
            min="1000"
            className="bet-input"
          />
          
        </div>

        <button
  id="betButton"
  className={`start-bet  ${isWithdrawn || isGameFinished ? 'waiting-button' : ''}`} 
  onClick={handleButtonClick}
  style={{
    // Dacă jocul este activ și nu s-a retras profitul, aplică gradientul portocaliu pentru butonul de "WITHDRAWAL"
    backgroundImage: isGameActive && !isWithdrawn 
      ? 'linear-gradient(to right, orange 70%, rgba(255, 165, 0, 0.5) 100%)'
      // Dacă nu este activ sau jocul e terminat, aplică gradientul dorit
      : 'linear-gradient(82.3deg, #77749b 10.8%, #9d99c5 94.3%)',
    backgroundColor: isWithdrawn || isGameFinished ? '#ccc' : '', // Fundalul devine #ccc pentru WAITING
    color: 'white', // Text alb pentru toate stările butonului
    pointerEvents: isWithdrawn || isGameFinished ? 'none' : 'auto' // Dezactivăm clicurile
  }}
>
{isWithdrawn || isGameFinished 
  ? 'WAITING' 
  : isGameActive 
    ? `WITHDRAWAL ${profit.toFixed(2)} $STAR` 
    : `START BET (${user.rocketTicket})`
}
</button>


      </div>
    </div>
  );
};

export default Bet;
