import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importăm useNavigate pentru redirecționare
import { UserContext } from '../context/UserContext'; // Importăm contextul utilizatorului
import pb from '../pocketbase';
import './App.css';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false); // Stare pentru a indica procesul de login
  const navigate = useNavigate(); // Hook-ul pentru navigare
  
  const { loginUser } = useContext(UserContext); // Preluăm funcția loginUser din context

  const handleLogin = async () => {
    if (!username || !password) {
      setMessage('Please enter both username and password.');
      return;
    }

    setIsLoggingIn(true); // Indicăm că procesul de logare a început

    try {
      const authData = await pb.collection('users').authWithPassword(username, password); // Autentificare cu username și parolă
      console.log("User logged in:", authData);
      setMessage('Login successful! Redirecting...');

      // Apelăm funcția loginUser din context pentru a stoca autentificarea în localStorage
      loginUser();

      // După logare reușită, redirecționează utilizatorul
      setTimeout(() => {
        navigate('/home'); // Redirecționează către pagina principală
      }, 1500); // Mică întârziere pentru a afișa mesajul de succes
    } catch (error) {
      console.error('Error during login:', error);
    
      // Logăm întregul obiect de eroare pentru debugging
      console.log('Full error object:', JSON.stringify(error, null, 2));
      // Verificăm dacă este o eroare de autentificare sau o eroare critică
      if (error.response && error.response.status === 400) {
        // Autentificare eșuată (email sau parolă greșită)
        setMessage('Invalid email or password. Please try again.');
      } else if (error.status === 0 || error.response === undefined) {
        // Eroare critică de rețea sau server căzut
        setMessage('There are issues on our side. Please try again later.');
      } else if (error.status === 403 || error.response === undefined) {
        // Eroare critică de rețea sau server căzut
        setMessage(`${error.message}`);
            }  else {
        // Mesaj fallback generic dacă nu există detalii de eroare clare
        setMessage('Wrong username or password');
      }

      

      
      

      setIsLoggingIn(false); // Resetăm starea de logare
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-image-container">
          <img 
            src="https://starlessx.com/assets/img/images/login-img.png" 
            alt="Starless Games" 
            className="login-image"
          />
        </div>
        <div className="login-body">
          <div className="login-header">
            <i className="login-icon fas fa-cubes fa-3x"></i>
            <span className="login-logo">Welcome back</span>
          </div>
          <h5 className="login-title">Login to your account</h5>
          <input 
            className="login-input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)} // Folosim username în loc de email
          />
          <input 
            className="login-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="login-button" onClick={handleLogin} disabled={isLoggingIn}>
            {isLoggingIn ? 'Logging in...' : 'Login'}
          </button>
          <p className="login-message">{message}</p>
          <p className="login-register">
            Don't have an account? <a className="login-register-link" href="/register">Register here</a>
          </p>
          
        </div>
      </div>
    </div>
  );
}

export default Login;
