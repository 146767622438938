import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { FaTwitter, FaInstagram, FaUserPlus, FaCheckCircle } from 'react-icons/fa';
import pb from '../pocketbase'; // Importăm clientul PocketBase

pb.autoCancellation(false);

const tasks = [
  { id: 1, name: "Follow us on Twitter", description: "Get a Case as a reward.", action: "https://twitter.com/StarlessXCore", reward: { containers: 1 } },
  { id: 2, name: "Follow us on Instagram", description: "Get a Case as a reward.", action: "https://www.instagram.com/starlessxcore/", reward: { containers: 1 } },
  { id: 3, name: "Like our Twitter post", description: "Get a Case as a reward.", action: "https://x.com/StarlessXCore/status/1827305729255956825", reward: { containers: 1 } },
  { id: 4, name: "Invite 15 friends", description: "Receive 3 Cases as a reward.", target: 15, reward: { containers: 3 } }
];

const TasksSection = () => {
  const { user, updateUser } = useContext(UserContext);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [canCollectTasks, setCanCollectTasks] = useState([]);
  const [waitingTasks, setWaitingTasks] = useState([]);
  const [activeTask, setActiveTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Funcție pentru a inițializa task-urile din DB
  useEffect(() => {
    const fetchUserTasks = async () => {
      setError(null);
      console.log("Fetching tasks for user:", user.id); 
  
      try {
        const userRecord = await pb.collection('users').getOne(user.id, {
          requestKey: `get-tasks-${user.id}` 
        });
  
        console.log("Fetched user data from PocketBase:", userRecord);
  
        // Verificăm dacă datele primite conțin task-urile corecte
        console.log("Completed Tasks:", userRecord.completedTasks);
        console.log("Can Collect Tasks:", userRecord.canCollectTasks);
        console.log("Waiting Tasks:", userRecord.waitingTasks);
  
        setCompletedTasks(userRecord.completedTasks || []);
        setCanCollectTasks(userRecord.canCollectTasks || []);
        setWaitingTasks(userRecord.waitingTasks || []);
        console.log("Tasks successfully initialized.");
      } catch (fetchError) {
        console.error("Error fetching user tasks:", fetchError);
        setError('Error fetching tasks from the database.');
      }
    };
  
    if (user.id) {
      fetchUserTasks();
    }
  }, [user.id]);
  

  // Funcție pentru a salva task-urile în baza de date
  const saveTasksToDB = async (updatedCompletedTasks, updatedCanCollectTasks, updatedWaitingTasks) => {
    console.log("Saving tasks to DB...");
  
    try {
      // Obținem toate datele utilizatorului înainte de a face update
      const userRecord = await pb.collection('users').getOne(user.id);
  
      // Verificăm ce alte date există deja în baza de date pentru acest utilizator
      console.log("Current user data before update:", userRecord);
  
      // Combinăm datele curente ale utilizatorului cu task-urile actualizate
      const updatedUserData = {
        ...userRecord, // Menținem toate datele existente
        completedTasks: updatedCompletedTasks || userRecord.completedTasks || [],
        canCollectTasks: updatedCanCollectTasks || userRecord.canCollectTasks || [],
        waitingTasks: updatedWaitingTasks || userRecord.waitingTasks || [],
      };
  
      // Verificăm datele care urmează să fie salvate
      console.log("Updated user data:", updatedUserData);
  
      // Facem update în baza de date doar la task-uri fără a șterge alte câmpuri
      await pb.collection('users').update(user.id, updatedUserData);
  
      console.log("Tasks saved successfully.");
    } catch (error) {
      console.error("Error saving tasks to DB:", error);
      setError('Failed to save tasks to the database.');
    }
  };
  
  

  // Funcție pentru a verifica task-urile din `waitingTasks`
  const checkWaitingTasks = () => {
    const now = Date.now();
    const updatedCanCollectTasks = [...canCollectTasks];
    const updatedWaitingTasks = waitingTasks.filter(({ taskId, expireAt }) => {
      if (expireAt <= now) {
        if (!updatedCanCollectTasks.includes(taskId)) {
          updatedCanCollectTasks.push(taskId); // Mutăm task-ul la canCollectTasks
        }
        return false;
      }
      return true;
    });

    setCanCollectTasks(updatedCanCollectTasks);
    setWaitingTasks(updatedWaitingTasks);
    
    saveTasksToDB(completedTasks, updatedCanCollectTasks, updatedWaitingTasks); // Salvăm imediat
  };

  // Setăm un interval pentru a verifica periodic waitingTasks
  useEffect(() => {
    const intervalId = setInterval(checkWaitingTasks, 1000); 
    return () => clearInterval(intervalId);
  }, [waitingTasks, canCollectTasks]);

  // Începem un task și îl mutăm în `waitingTasks`
  const handleTaskStart = (taskId) => {
    setActiveTask(taskId);
    const task = tasks.find((task) => task.id === taskId);
    if (task?.action) {
      window.open(task.action, "_blank"); 
    }

    const expireAt = Date.now() + 6000; // 10 minute de acum
    const updatedWaitingTasks = [...waitingTasks, { taskId, expireAt }];

    setWaitingTasks(updatedWaitingTasks);
    saveTasksToDB(completedTasks, canCollectTasks, updatedWaitingTasks); // Salvăm imediat în baza de date

    // Afișăm modalul doar ca feedback, fără a reinicializa task-ul aici
    setTimeout(() => setShowModal(true), 5000); 
  };

  // Închidem modalul fără a inițializa din nou task-ul
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Finalizăm un task și mutăm în `completedTasks`
  const handleTaskCompletion = (taskId) => {
    console.log("Task completion process started for task:", taskId);
    
    const updatedCompletedTasks = [...completedTasks, taskId];
    const updatedCanCollectTasks = canCollectTasks.filter((id) => id !== taskId); 
    const updatedWaitingTasks = waitingTasks.filter(({ taskId: id }) => id !== taskId); 

    setCompletedTasks(updatedCompletedTasks);
    setCanCollectTasks(updatedCanCollectTasks);
    setWaitingTasks(updatedWaitingTasks);

    console.log("Updated state: Completed:", updatedCompletedTasks, "Collect:", updatedCanCollectTasks);

    // Actualizăm container-ele utilizatorului
    const taskToComplete = tasks.find((task) => task.id === taskId);
    if (taskToComplete?.reward?.containers) {
      updateUser({ containers: user.containers + taskToComplete.reward.containers });
    }

    saveTasksToDB(updatedCompletedTasks, updatedCanCollectTasks, updatedWaitingTasks); // Salvăm imediat după colectare
  };

  return (
    <div className="game-display">
      {showModal && <div className="dim-background"></div>}
      <div className={showModal ? 'blurred-background' : ''}>
        <h2>Available Tasks</h2>

        
        <div className="tasks-list">
          {tasks.map((task) => (
            <div key={task.id} className={`task-item ${completedTasks.includes(task.id) ? 'completed' : ''}`}>
              <div className="task-info">
                <div className="task-icon">
                  {task?.name?.includes('Twitter') && <FaTwitter />}
                  {task?.name?.includes('Instagram') && <FaInstagram />}
                  {task?.name?.includes('Invite') && <FaUserPlus />}
                  {completedTasks.includes(task.id) && <FaCheckCircle />}
                </div>
                <div className="task-details">
                  <h3>{task?.name}</h3>
                  <p>{task?.description}</p>
                </div>
              </div>

              {/* Invites task handling */}
              {task.id === 4 && !completedTasks.includes(task.id) && (
                <div className="invite-progress">
                  {user.invitedUsers < task.target && (
                    <span>{user.invitedUsers}/{task.target} Users</span>
                  )}
                  {user.invitedUsers >= task.target && (
                    <button
                      onClick={() => handleTaskCompletion(task.id)}
                      className="task-btn collect-btn"
                    >
                      Collect
                    </button>
                  )}
                </div>
              )}

              {/* Buton pentru a începe task-ul */}
              {!completedTasks.includes(task.id) && !canCollectTasks.includes(task.id) && task.id !== 4 && (
                <button
                  onClick={() => handleTaskStart(task.id)}
                  className="task-btn complete-task-btn"
                >
                  Complete
                </button>
              )}

              {/* Buton pentru a colecta task-ul */}
              {!completedTasks.includes(task.id) && canCollectTasks.includes(task.id) && task.id !== 4 && (
                <button
                  onClick={() => handleTaskCompletion(task.id)}
                  className="task-btn collect-btn"
                >
                  Collect
                </button>
              )}

              {/* Task completat */}
              {completedTasks.includes(task.id) && (
                <button className="task-btn completed-task-btn" disabled>
                  Completed
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {showModal && (
        <div className="task-modal">
          <div className="task-modal-content">
            <h2>Action is under review</h2>
            <p>Your action will be verified. Please wait 10 minutes to collect your reward.</p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TasksSection;
