import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { FaUserPlus, FaUserCheck, FaGift } from 'react-icons/fa';

const RefferalsSection = () => {
  const { user } = useContext(UserContext);

  const handleCopyLink = () => {
    const referralLink = `https://games.starlessx.com/register?inviter=${user.username}`; // Use inviter's username in the link
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <div className="game-display">
      <div className="referral-steps-container">
        <h2 className="referral-steps-title">How It Works</h2>
        <div className="referral-steps">
          <div className="step">
            <div className="step-icon">
              <FaUserPlus style={{ marginRight: "0px" }}/>
            </div>
            <h3 className="step-title">1. Invite a User</h3>
            <p className="step-description">Share your referral link to invite a friend.</p>
          </div>

          <div className="step">
            <div className="step-icon">
              <FaUserCheck style={{ marginRight: "0px" }}/>
            </div>
            <h3 className="step-title">2. User Registers</h3>
            <p className="step-description">Your friend registers on the platform.</p>
          </div>

          <div className="step">
            <div className="step-icon">
              <FaGift style={{ marginRight: "0px" }}/>
            </div>
            <h3 className="step-title">3. Get a Case</h3>
            <p className="step-description">Both you and your friend receive a case.</p>
          </div>
        </div>
      </div>

      <div className="referral-link-container">
        <h3>Your Invitation Link</h3>
        <div className="referral-link-box">
          <p>{`https://games.starlessx.com/register?inviter=${user.username}`}</p> {/* Show the link with the username */}
          <button onClick={handleCopyLink}>Copy</button>
        </div>
      </div>
    </div>
  );
};

export default RefferalsSection;
