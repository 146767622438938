import React, { useState, useEffect, useContext, useRef } from 'react';
import { FaHome, FaBook, FaStore, FaWallet, FaUserFriends, FaSearch, FaShoppingCart, FaUserCircle, FaArrowUp, FaArrowDown, FaExchangeAlt, FaHandPointUp } from 'react-icons/fa';
import { RiNotification4Fill } from "react-icons/ri";
import { RiMenu2Line } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import { LuLibrary } from "react-icons/lu";
import './App.css';
import GameSection from './GameSection';
import { UserContext } from '../context/UserContext';
import { FaWindowClose } from "react-icons/fa";
import { PiHandTapFill } from "react-icons/pi";
import LogoutButton from './LogoutButton';
import sgxImage from './SGX.png';







const GamePage = () => {
  const { user } = useContext(UserContext);

  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [showPageModal, setShowPageModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

const toggleSidebar = () => {
  setSidebarVisible(!sidebarVisible);
};

const closeSidebar = () => {
  setSidebarVisible(false);
};

const openPageModal = () => {
  setShowPageModal(true);
};

const [menuVisible, setMenuVisible] = useState(false);
const userMenuRef = useRef(null);

const toggleMenu = () => {
  setMenuVisible(!menuVisible);
};

const handleClickOutside = (e) => {
  if (userMenuRef.current && !userMenuRef.current.contains(e.target)) {
    setMenuVisible(false);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

 


  return (
    <div className="wallet-page">
      <nav className={`sidebar ${sidebarVisible ? 'show' : ''}`}>
        <div className="logo">
          <img src="./logo.png" alt="StarlessX-Games-Logo" />
        </div>
        <div className="close-area" onClick={closeSidebar}>
         <FaWindowClose className="close-icon" />
        </div>
        <ul className="menu">
          <hr className='menu-line' />
          <a href="/home" className='section-name'><FaHome style={{ fontSize: '20px' }} /> Home</a>
          <a href="/tap-to-earn" className='current-section'><PiHandTapFill style={{ fontSize: '20px' }} /> Tap To Earn</a>
        <a href="#" onClick={() => { openPageModal(); closeSidebar(); }} className='section-name'>
          <LuLibrary style={{ fontSize: '20px' }} /> Library
        </a>
          <hr className='menu-line' />
          <a href="#" onClick={() => { openPageModal(); closeSidebar(); }} className='section-name'>
          <FaStore style={{ fontSize: '20px' }} /> Store
        </a>
          <a href="/wallet" className='section-name'><FaWallet style={{ fontSize: '20px' }} /> Wallet</a>
          <hr className='menu-line' />
          <a href="#" onClick={() => { openPageModal(); closeSidebar(); }} className='section-name'>
          <FaUserFriends style={{ fontSize: '20px' }} /> Friends
        </a>
        <a href="https://starlessx.com/contact" target="_blank" className='support-button-side'>
          <TbMessages />Get Support
        </a> 
        </ul>
      </nav>
      <main className="content">
      {showPageModal && <div className="dim-background"></div>} {/* Stratul de întunecare */}

        <div className="header">
        <div className="sidebar-toggler" onClick={toggleSidebar}>
        <RiMenu2Line className="toggler-button" />
      </div>
        <div className="nav-logo">
          <img src="./logo.png" alt="StarlessX-Games-Logo" />
        </div>
          <div className="search-container">
            {showSearchIcon && <FaSearch className="search-icon" />}
            <input 
              type="text" 
              placeholder="Type to search" 
              className="search-input" 
            />
          </div>
          <div className="icons">
          <li className='total-SGX-balance' style={{ listStyle: 'none' }}>
            {user.sgxBalance ? user.sgxBalance.toFixed(2) : '0.00'} SGX
          </li>
          <a href="https://starlessx.com/contact" target="_blank" className='support-button'>
          <TbMessages />Get Support
        </a> 
          <img src={sgxImage} onClick={toggleMenu} alt="SGX" className="user-image user-menu-trigger-icon" />
            {menuVisible && (
          <div ref={userMenuRef} className="user-dropdown-menu">
            <div className="user">
      <img src={sgxImage} alt="SGX" className="user-image" />
      <div className="user-menu">
        <div className="user-info">
          <div className='user-name-tag'>
            <span className="user-name">{user.username}</span>
          </div>
          <div className="user-mail">
            <span className="address">User</span>
          </div>
        </div>
      </div>
    </div>
           <hr className='menu-line' />
           <ul className='down-menu'>
           <LogoutButton className="logout-button"/>
           </ul>
          </div>
        )}
          </div>
          
        </div>
        <section className="tap-to-earn">
        <GameSection />
        </section>
        {showPageModal && (
                <div className="page-modal">
                    <div className="page-modal-content">
                        <h2>Page Under Development</h2>
                        <p>This page is still under development. As StarlessX is in its early stages, many features and components are still being worked on. Thank you for your understanding and patience.</p>
                        <button onClick={() => setShowPageModal(false)}>Close</button>
                    </div>
                </div>
            )}
      </main>
    </div>
  );
};

export default GamePage;
