import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import pb from '../pocketbase';
import { useUserContext } from '../context/UserContext';
import './App.css';
import axios from 'axios'; // Pentru a obține adresa IP

function Register() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [message, setMessage] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [deviceInfo, setDeviceInfo] = useState('');
  const navigate = useNavigate();
  const { inviteUser } = useUserContext();

  useEffect(() => {
    // Obține adresa IP a utilizatorului
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        setIpAddress(response.data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    // Obține informațiile despre dispozitiv și browser
    setDeviceInfo(navigator.userAgent);
  }, []);

  const handleRegister = async () => {
    if (!username || !password || !confirmPassword) {
      setMessage('Please fill in all fields.');
      return;
    }
  
    if (username.length < 3) {
      setMessage('Username must be at least 3 characters long.');
      return;
    }
  
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
  
    if (!isConfirmed) {
      setMessage('Please confirm the checkbox to proceed with registration.');
      return;
    }
  
    try {
      // Verificăm dacă există deja două conturi de la același IP sau dispozitiv
      const existingUsers = await pb.collection('users').getFullList({
        filter: `ipAddress="${ipAddress}" || deviceInfo="${deviceInfo}"`,
      });
  
      if (existingUsers.length >= 2) {
        setMessage(
          'You have exceeded the maximum limit of 2 registrations. For more information or assistance, you can contact us <a href="https://starlessx.com/contact" target="_blank">here</a>.'
        );
        return;
      }
  
      // Creăm noul utilizator și setăm câmpurile relevante cu liste goale
      const newUser = await pb.collection('users').create({
        username,
        password,
        passwordConfirm: confirmPassword,
        sgxBalance: 0,
        starBalance: 0,
        tapEarnRate: 1,
        energyRegenRate: 2,
        containers: 0,
        energy: 0,
        rankIndex: 0,
        xp: 0,
        invitedUsers: 0,
        ipAddress, // Stocăm adresa IP
        deviceInfo, // Stocăm informațiile despre dispozitiv
        waitingTasks: [], // Inițializare ca listă goală
        canCollectTasks: [], // Inițializare ca listă goală
        completedTasks: [], // Inițializare ca listă goală
      });
  
      console.log('New user created:', newUser);
  
      const urlParams = new URLSearchParams(window.location.search);
      const inviterUsername = urlParams.get('inviter');
  
      if (inviterUsername) {
        await inviteUser(inviterUsername, newUser.id);
        console.log('Invite function called for:', inviterUsername);
      }
  
      setMessage('Registration successful! You can now log in.');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
  
    } catch (error) {
      console.error('Error during registration:', error);
      setMessage('Failed to create record.');
    }
  };
  

  return (
    <div className="register-container">
      <div className="register-card">
        <div className="register-image-container">
          <img 
            src="https://starlessx.com/assets/img/images/login-img.png" 
            alt="Starless Games" 
            className="register-image"
          />
        </div>
        <div className="register-body">
          <div className="register-header">
            <i className="register-icon fas fa-cubes fa-3x"></i>
            <span className="register-logo">Welcome</span>
          </div>
          <h5 className="register-title">Create a new account</h5>
          <input 
            className="register-input"
            type="text"
            placeholder="Username"
            maxLength={20}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input 
            className="register-input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input 
            className="register-input"
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="register-check">
            <label htmlFor="confirmCheck">
              <input
                type="checkbox"
                id="confirmCheck"
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
              By ticking this box, you confirm that if you forget your login credentials or password, account recovery will not be possible, and StarlessX will not be held responsible for any lost data. Our goal is to maintain a decentralized platform, and as such, we do not collect personal information for account recovery purposes.
            </label>
          </div>
          <button className="register-button" onClick={handleRegister}>Register</button>
          <p className="register-message" dangerouslySetInnerHTML={{ __html: message }}></p>
          <p className="register-login">
            Already have an account? <a className="register-login-link" href="/login">Login here</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
