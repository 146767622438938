// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { SGXContext } from './SGXContext';
import pb from '../pocketbase'; // Importăm clientul PocketBase

export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext); // Adaugă această linie


const ranks = [
    { name: 'Rank 1', requiredTaps: 50, image: './rank1.png', energyLimit: 100 },
    { name: 'Rank 2', requiredTaps: 70, image: './rank2.png', energyLimit: 200 },
    { name: 'Rank 3', requiredTaps: 100, image: './rank3.png', energyLimit: 300 },
    { name: 'Rank 4', requiredTaps: 120, image: './rank4.png', energyLimit: 400 },
    { name: 'Rank 5', requiredTaps: 150, image: './rank5.png', energyLimit: 500 },
    { name: 'Rank 6', requiredTaps: 200, image: './rank6.png', energyLimit: 600 },
    { name: 'Rank 7', requiredTaps: 300, image: './rank7.png', energyLimit: 700 },
    { name: 'Rank 8', requiredTaps: 400, image: './rank8.png', energyLimit: 800 },
    { name: 'Rank 9', requiredTaps: 500, image: './rank9.png', energyLimit: 900 },
    { name: 'Rank 10', requiredTaps: 600, image: './rank10.png', energyLimit: 1000 },
];

export const UserProvider = ({ children }) => {
    const { generatedSgxBalance } = useContext(SGXContext);
    

    const [user, setUser] = useState(() => {
        // Inițializăm utilizatorul din localStorage dacă există
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : {
            username: '',
            email: '',
            sgxBalance: 0,
            starBalance: 0,
            tapEarnRate: 1,
            energyRegenRate: 2,
            containers: 0,
            energy: 0,
            rankIndex: 0,
            prevRankIndex: 0, 
            xp: 0,
            invitedUsers: 0,
            rocketTicket: 0,
            waitingTasks: [], // Inițializare ca listă goală
            canCollectTasks: [], // Inițializare ca listă goală
            completedTasks: [], // Inițializare ca listă goală
        };
    });

    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Inițializăm autentificarea din localStorage
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    
    const [isFetching, setIsFetching] = useState(true);
    const userDataRef = useRef(user);

    // Adaugă în UserProvider

    const getUsername = () => {
      return user ? user.username : null; // Verifică dacă utilizatorul există
    };

    const inviteUser = async (inviterUsername, newUserId) => {
      try {
        // Delay of 2 seconds to simulate processing time
        await new Promise((resolve) => setTimeout(resolve, 2000));
    
        // Fetch inviter by username
        const inviter = await pb.collection('users').getFirstListItem(`username="${inviterUsername}"`);
    
        if (!inviter) {
          throw new Error('Inviter not found');
        }
    
        // Update inviter's invited users count and containers
        await pb.collection('users').update(inviter.id, {
          invitedUsers: inviter.invitedUsers + 1,
          containers: inviter.containers + 1,  // Offer a container to the inviter
        });
    
        // Update the new user to give them a container
        await pb.collection('users').update(newUserId, {
          containers: 1,  // Assign a container to the new user
        });
    
        console.log('User updated successfully:', inviter);
      } catch (error) {
        console.error('Error updating invited users:', error);
      }
    };
    
    
    
  
    
    
    


    const fetchUserData = async (userId) => {
        try {
            const userRecord = await pb.collection('users').getOne(userId);
            setUser((prevUser) => ({
                ...prevUser,
                ...userRecord,
            }));
            userDataRef.current = { ...user, ...userRecord };
            setIsFetching(false);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const saveUserData = async (userId, data) => {
        try {
            await pb.collection('users').update(userId, data);
        } catch (error) {
            console.error('Error saving user data:', error);
        }
    };

    useEffect(() => {
        const currentUser = pb.authStore.model;
        if (currentUser && isFetching) {
            fetchUserData(currentUser.id);
        }
    }, [pb.authStore.model, isFetching]);

    useEffect(() => {
        const currentUser = pb.authStore.model;
        if (currentUser && !isFetching) {
            saveUserData(currentUser.id, user);
            // Salvăm user-ul în localStorage
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user, isFetching]);

    useEffect(() => {
        if (!isFetching) {
            setUser((prevUser) => ({
                ...prevUser,
                sgxBalance: prevUser.sgxBalance + generatedSgxBalance,
            }));
        }
    }, [generatedSgxBalance, isFetching]);

    const updateUser = (updates) => {
        setUser((prevUser) => ({
            ...prevUser,
            ...updates,
        }));
        userDataRef.current = { ...user, ...updates };
        // Actualizăm și în localStorage
        localStorage.setItem('user', JSON.stringify({ ...user, ...updates }));
    };

    const resetUser = () => {
        setUser({
            username: '',
            email: '',
            sgxBalance: 0,
            starBalance: 0,
            tapEarnRate: 1,
            energyRegenRate: 2,
            containers: 0,
            energy: 0,
            rankIndex: 0,
            xp: 0,
            invitedUsers: 0,
            rocketTicket: 0,
            waitingTasks: [], // Inițializare ca listă goală
            canCollectTasks: [], // Inițializare ca listă goală
            completedTasks: [], // Inițializare ca listă goală
        });
        setIsFetching(true);
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('user');
    };

    const loginUser = () => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
    };

    const regenerateEnergy = () => {
        setUser((prevUser) => {
            const currentRank = ranks[prevUser.rankIndex];
            const energyLimit = currentRank.energyLimit;
            if (prevUser.energy < energyLimit) {
                return { ...prevUser, energy: prevUser.energy + 1 };
            }
            return prevUser;
        });
    };

    const handleTap = () => {
        setUser((prevUser) => {
            if (prevUser.energy > 0) {
                const newBalance = prevUser.starBalance + prevUser.tapEarnRate;
                const newXP = prevUser.xp + 1;
                let newRankIndex = prevUser.rankIndex;
                let newContainers = prevUser.containers;

                if (newXP >= ranks[prevUser.rankIndex].requiredTaps) {
                    if (prevUser.rankIndex < ranks.length - 1) {
                        newRankIndex = Math.min(prevUser.rankIndex + 1, ranks.length - 1);
                        newContainers += 1;
                    }
                }

                return {
                    ...prevUser,
                    starBalance: newBalance,
                    xp: newRankIndex > prevUser.rankIndex ? 0 : newXP,
                    rankIndex: newRankIndex,
                    prevRankIndex: prevUser.rankIndex,
                    containers: newContainers,
                    energy: prevUser.energy - 1,
                };
            }
            return prevUser;
        });
    };

    const updateRocketTicket = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            rocketTicket: prevUser.rocketTicket + amount,
        }));
    };

    const updateStarBalance = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            starBalance: prevUser.starBalance + amount,
        }));
    };

    const updateSGXBalance = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            sgxBalance: prevUser.sgxBalance + amount,
        }));
    };

    const updateEnergyRegenRate = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            energyRegenRate: Math.max(0.1, prevUser.energyRegenRate - amount),
        }));
    };

    const updateTapEarnRate = (amount) => {
        setUser((prevUser) => ({
            ...prevUser,
            tapEarnRate: Math.min(10, prevUser.tapEarnRate + amount),
        }));
    };

    const decrementContainerCount = () => {
        setUser((prevUser) => ({
            ...prevUser,
            containers: Math.max(prevUser.containers - 1, 0),
        }));
    };

    const updateInvitedUsers = (count) => {
        setUser((prevUser) => ({
            ...prevUser,
            invitedUsers: prevUser.invitedUsers + count,
        }));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            regenerateEnergy();
        }, user.energyRegenRate * 1000);
        return () => clearInterval(interval);
    }, [user.energyRegenRate]);

    return (
        <UserContext.Provider
            value={{
                user,
                isAuthenticated,
                loginUser,
                handleTap,
                regenerateEnergy,
                updateRocketTicket,
                updateStarBalance,
                updateSGXBalance,
                updateEnergyRegenRate,
                updateTapEarnRate,
                decrementContainerCount,
                updateInvitedUsers,
                updateUser,
                resetUser,
                inviteUser,
                getUsername,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
